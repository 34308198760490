import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { RolePagesService } from "../_services/role-pages.service";

@Directive({
  selector: "[appPermissionBased]",
})
export class PermissionBasedDirective {
  @Input() appPermissionBased: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private rolePagesService: RolePagesService
  ) {}

  ngOnInit() {
    this.rolePagesService.currentPage$.subscribe(
      (userPermissions: string[]) => {
        const hasAccess = this.rolePagesService.checkForAccess(
          this.appPermissionBased
        );
        if (hasAccess) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      }
    );
  }
}
